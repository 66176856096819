@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:400,400i,700,800,800i,900i&display=swap');

:root {
  --bg: hsl(0, 0%, 98%);
  --bg-offset: hsl(0, 0%, 100%);
  --border: rgba(0, 0, 0, 0.1);
  --clr-lightGray:#F9F6FB;
  --clr-purple: #4E0679;
  --radius: 2rem;
  --thumbnailSize: 8rem;
  --quiz-modal-margin: 20px;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
}

img {
    width: 100%;
}

ul,
ol {
    list-style: none;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  color: #590959;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  background: var(--clr-lightGray);
}

.loading {
    margin: 10rem;
    text-align: center;
}

.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: var(--clr-lightGray);
}

.header {
    height: 180px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: var(--clr-lightGray);
    -webkit-transition: top .3s ease .15s;
    -moz-transition: top .3s ease .15s;
    -o-transition: top .3s ease .15s;
    -ms-transition: top .3s ease .15s;
    transition: top .3s ease .15s;
}

.main-content {
    display: flex;
    flex-flow: column;
}

.sub-header {
    width: 100%;
    background: var(--clr-lightGray);
    display: flex;
    flex-flow: row;
}

.sticky-sub-header {
    position: fixed;
    top: 0px;
}

.sub-header-content {
    display: flex;
    flex-flow: column;
}

.enrichment-rows {
    width: 100%;
    padding-bottom: 20px;
    display: flex;
    flex-flow: row;
}

.sticky-enrichment-rows {
    padding-top: 180px;
}

.search-wrapper {
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 20px;
}

.search-input {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0xNS44NTMgMTYuNTZjLTEuNjgzIDEuNTE3LTMuOTExIDIuNDQtNi4zNTMgMi40NC01LjI0MyAwLTkuNS00LjI1Ny05LjUtOS41czQuMjU3LTkuNSA5LjUtOS41IDkuNSA0LjI1NyA5LjUgOS41YzAgMi40NDItLjkyMyA0LjY3LTIuNDQgNi4zNTNsNy40NCA3LjQ0LS43MDcuNzA3LTcuNDQtNy40NHptLTYuMzUzLTE1LjU2YzQuNjkxIDAgOC41IDMuODA5IDguNSA4LjVzLTMuODA5IDguNS04LjUgOC41LTguNS0zLjgwOS04LjUtOC41IDMuODA5LTguNSA4LjUtOC41eiIvPjwvc3ZnPg==");
    background-color: var(--bg-offset);
    background-size: 16px 16px;
    background-position: left 10px center;
    background-repeat: no-repeat;
    padding: 1.4em 2em;
    padding-left: 2.7em;
    border: 1px solid var(--border);
    border-radius: var(--radius);
    box-shadow: 0px 4px 6px var(--border);
    transition: all 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
    font-size: 14px;
    font-weight: 700;
    color: #764593;
    height: 44px;
    width: 100%;
}

.search-input:hover {
    box-shadow: 0px 0px 0px var(--border);
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
   height: 16px;
   width: 16px;
   display: block;
   background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
   background-repeat: no-repeat;
   background-size: 16px;
   cursor: pointer;
}

.enrichment-rows-margin {
    width: 30%;
}

.enrichment-rows-width {
    width: 70%;
}

.auto-width-col {
    width: auto;
}

.fill-width-col {
    width: 99%;
}

.fifty-percent-width-col {
    width: 50%;
}

.sixty-width-col {
    width: 60px;
}

.center-aligned-col {
    text-align: center;
}

.auto-margins {
    margin-top: auto;
    margin-bottom: auto;
}

.book-icon {
    height: 120px;
    width: auto;
    clip-path: inset(0px 15px 0px 15px round 15px);
}

.book-details-wrapper {
    display: flex;
    flex-flow: column;
}

.book-title {
    font-size: 26px;
    font-weight: 900;
    color: var(--clr-purple);
    vertical-align: bottom;
    padding-left: 10px;
}

.book-publisher {
    height: 42px;
    font-size: 18px;
    font-weight: 700;
    color: var(--clr-purple);
    vertical-align: top;
    text-wrap: nowrap;
    padding-left: 10px;
}

.enrichments-title {
    font-size: 16px;
    font-weight: 900;
    color: var(--clr-purple);
}

.card-grid {
   overflow: auto;
   display: grid;
   gap: 10px;
}

.card {
    overflow: hidden;
    background-color: #F2E6F9;
    padding: 12px 8px 12px 8px;
    display: flex;
    flex-flow: row;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 100%;
    height: 100%;
    justify-content: space-between;
    border-radius: 12px;
}

.card-image {
    height: 24px;
    width: 24px;
    object-fit: cover;
    margin-top: 5px;
}

.card-text {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.card-title {
    text-align: left;
    word-wrap: break-word;
    height: 100%;
    font-size: 14px;
    font-weight: 900;
    color: var(--clr-purple);
    margin-left: 10px;
}

.card-subText {
    text-align: left;
    word-wrap: break-word;
    height: 100%;
    font-size: 10px;
    font-weight: 700;
    color: #764593;
    margin-left: 10px;
}

.action-button {
    display: table;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    height: 34px;
    width: 80px;
    background: var(--clr-purple);
    cursor: pointer;
    border-radius: 25px;
}

.action-button .text {
    display: table-cell;
    vertical-align: middle;
    font-size: 12px;
    font-weight: 900;
    text-decoration: none;
    color: #FFFFFF;
}

.hidden-style {
    display: none;
}

.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(28, 28, 28, 0.19);
  backdrop-filter: blur(6px);
  opacity: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.3s;
  animation-name: modal-video;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -ms-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  z-index: 100;
  overflow: auto;
}

.model-viewer-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 770px;
    height: 554px;
    margin-left: -385px;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: -277px;
}

.model-viewer-content {
  text-align: right;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.viewer-title-panel {
    position: absolute;
    z-index: 200;
    width: 100%;
    background: transparent;
    display: flex;
    flex-flow: row;
}

.model-viewer-button-panel {
    position: absolute;
    bottom: 20px;
    z-index: 200;
    width: 100%;
    display: flex;
    flex-flow: row;
}

.modal-layout {
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
   border-radius: 16px;
   background: var(--clr-lightGray);
   margin-right: auto;
   margin-left: auto;
}

.modal-layout-fullscreen {
    border-radius: 0px;
}

.viewer-icon {
    width: 24px;
    height: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.overflow-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.viewer-title {
    font-size: 24px;
    font-weight: 900;
    color: var(--clr-purple);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    margin-top: 14px;
}

.viewer-close-button {
    background: transparent;
    border: none;
    margin: 20px;
    cursor: pointer;
}

.viewer-close-dummy-button {
    margin: 20px;
}

.viewer-close-button-image {
    height: 20px;
    width: 20px;
}

.viewer-modal-align {
  display: flex;
  position: relative;
  margin-top: 64px;
}

.model-viewer-action-button {
    display: flex;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    padding: 8px 16px 8px 16px;
    background: var(--clr-purple);
    cursor: pointer;
    border-radius: 25px;
    height:32px;
}

.model-viewer-action-button .text {
    font-size: 12px;
    font-weight: 900;
    color: #FFFFFF;
    display: inline-block;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-75%);
    -webkit-transform: translateY(-75%);
    transform: translateY(-50%);
    text-wrap: nowrap;
}

.model-viewer-action-button .image {
    height: 16px;
    width: 16px;
    margin-right: 5px;
    display: inline-block;
}

.model-viewer-style {
  z-index: 100;
  width: 100%;
  height: 100%;
  padding: 10px;
}

.model-viewer-padding {
    padding-bottom: 0px;
}

.media-player-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 770px;
    height: 554px;
    margin-left: -385px;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: -277px;
}

.media-player-content {
  text-align: right;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.media-player-style {
  z-index: 100;
  width: 770px;
  height: 426px;
}

.modal-spinner {
  position: fixed;
  background-color: transparent;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 128px;
  height: 128px;
}

.error-dialog-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 300px;
    height: 200px;
    margin-left: -150px;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: -100px;
}

.error-dialog-content {
  text-align: right;
  display: flex;
  flex-flow: column;
  height: 100%;
}

.error-dialog-text {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    padding: 10px;
}

.content-item-flex {
    display: flex;
}

.content-item {
    display: block;
}

.content-item-filler {
    display: block;
    flex: 1 auto;
}

.quiz-viewer-layout {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 600px;
    height: 660px;
    margin-left: -300px;
    margin-right: auto;
    margin-bottom: auto;
    margin-top: -310px;
}

.quiz-viewer-content {
  margin-left: var(--quiz-modal-margin);
  margin-right: var(--quiz-modal-margin);
  text-align: right;
  display: flex;
  flex-flow: column;
  margin-bottom: 25px;
  margin-top: 10px;
  min-height: 625px;
}

.no-margin {
    margin-left: 0px;
    margin-right: 0px;
}

.max-height-fit {
    max-height: fit-content !important;
}

.quiz-large-text {
    font-size: 50px;
    font-weight: 900;
    text-shadow: 0.5px 0 currentColor;
    letter-spacing: 0.5px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;
    max-height: 70px;
    overflow-wrap: anywhere;
}

.quiz-viewer-content .text {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin-top: 15px;
}

.quiz-complete-first-half {
  display: flex;
  flex-flow: column;
}

.quiz-complete-first-half .quiz-banner-completed {
    margin-top: -1.5rem;
}

.quiz-complete-first-half .stars {
    display: flex;
    flex-flow: row;
}

.quiz-complete-first-half .stars .star {
    z-index: 1000;
}

.quiz-complete-first-half .stars .leftstar {
    text-align: right;
}

.quiz-complete-first-half .stars .rightstar {
    text-align: left;
}

.quiz-complete-first-half .stars .star1 {
    width: 60%;
    height: 60%;
    margin-top: 40%;
}

.quiz-complete-first-half .stars .star2 {
    width: 100%;
    height: 100%;
}

.quiz-complete-first-half .stars .star3 {
    width: 60%;
    height: 60%;
    margin-top: 40%;
}

.quiz-question-content {
  min-height: 100%;
  margin-left: var(--quiz-modal-margin);
  margin-right: var(--quiz-modal-margin);
  text-align: right;
  display: flex;
  flex-flow: column;
  height: fit-content;
  margin-top: 10px;
  margin-bottom: 25px;
  min-height: 625px;
}

.quiz-question-content .breadcrumb {
    display: flex;
    flex-flow: row;
    overflow: auto;
}

.quiz-question-content .breadcrumb .text {
    font-size: 18px;
    font-weight: 600;
    padding: 15px;
    margin-top: 5px;
}

.quiz-question-content .title {
    font-size: 24px;
    font-weight: 900;
    text-shadow: 0.5px 0 currentColor;
    letter-spacing: 0.5px;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;
    max-height: 230px;
    overflow-wrap: anywhere;
    user-select: none;
}

.quiz-question-content .button {
    display: block;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    min-height: 90px;
    cursor: pointer;
    border-radius: 25px;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 6px;
    transition: all ease 0.3s;
    font-size: 20px;
    font-weight: 900;
    text-decoration: none;
    color: #111111;
    padding: 10px;
    user-select: none;
    border: none;
    flex-grow: 1;
    font-family: Nunito Sans,sans-serif;
}

.quiz-question-content .button:active {
  box-shadow: 0 3px 0 #B6BAB8;
  top: 3px;
}

.quiz-question-content .button-color1 {
    background: #FF7971;
}

.quiz-question-content .button-color2 {
    background: #FFA233;
}

.quiz-question-content .button-color3 {
    background: #FCEC34;
}

.quiz-question-content .button-color4 {
    background: #51DE65;
}

.quiz-question-content .button .text {
    display: table-cell;
    vertical-align: middle;
    font-size: 20px;
    font-weight: 900;
    text-decoration: none;
    color: #111111;
    padding: 10px;
    user-select: none;
}

.quiz-complete-height {
    min-height: 625px;
    width: 100%;
}

.allow-autoscroll {
    overflow: auto;
}

.full-height-width {
    height: 100%;
    width: 100%;
}

.half-height-full-width {
    height: 50%;
    width: 100%;
}

.quiz-complete-second-half {
    margin-top: -10%;
    margin-left: var(--quiz-modal-margin);
    margin-right: var(--quiz-modal-margin);
    display: flex;
    flex-flow: column;
    margin-bottom: 25px;
}

.quiz-button {
    display: table;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    height: 60px;
    width: 180px;
    background: var(--clr-purple);
    cursor: pointer;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 25px;
    box-shadow: 0px 2px 6px;
}

.quiz-button .text {
    display: table-cell;
    vertical-align: middle;
    font-size: 24px;
    font-weight: 900;
    text-decoration: none;
    color: #FFFFFF;
    padding: 10px;
}

.quiz-complete-title-margin {
    margin-top: 20px;
}

.right-answer {
    outline: 5px solid green;
    animation: blink 0.5s;
    animation-iteration-count: 3;
}

@keyframes blink { 50% { outline-color:#00000000; }  }

.wrong-answer {
    outline: 5px solid red;
}

@media (max-width: 800px) {
    .search-input {
        width: 100%;
    }
    .search-wrapper {
        justify-content: start;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 769px) {
    .model-viewer-layout {
        width: 700px;
        margin-left: -350px;
    }

    .media-player-layout {
        width: 700px;
        margin-left: -350px;
    }

    .media-player-style {
        width: 700px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }

    .model-viewer-padding {
        padding-bottom: 45px;
    }
}

@media screen and (max-width: 750px) {
    .model-viewer-layout {
        width: 700px;
        margin-left: -350px;
    }

    .media-player-layout {
        width: 700px;
        margin-left: -350px;
    }

    .media-player-style {
        width: 700px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }
}

@media screen and (max-width: 700px) {
    .model-viewer-layout {
        width: 650px;
        margin-left: -325px;
    }

    .media-player-layout {
        width: 650px;
        margin-left: -325px;
    }

    .media-player-style {
        width: 650px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }
}

@media screen and (max-width: 650px) {
    .quiz-viewer-layout {
        width: 600px;
        margin-left: -300px;
    }

    .model-viewer-layout {
        width: 600px;
        margin-left: -300px;
    }

    .media-player-layout {
        width: 600px;
        margin-left: -300px;
    }

    .media-player-style {
        width: 600px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }
}

@media screen and (max-width: 600px) {
    .quiz-viewer-layout {
        width: 550px;
        margin-left: -275px;
    }

    .model-viewer-layout {
        width: 550px;
        margin-left: -275px;
    }

    .media-player-layout {
        width: 550px;
        margin-left: -275px;
    }

    .media-player-style {
        width: 550px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }
}

@media screen and (max-width: 550px) {
    .quiz-viewer-layout {
        width: 500px;
        margin-left: -250px;
    }

    .model-viewer-layout {
        width: 500px;
        margin-left: -250px;
    }

    .media-player-layout {
        width: 500px;
        margin-left: -250px;
    }

    .media-player-style {
        width: 500px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .quiz-viewer-layout {
        width: 450px;
        margin-left: -225px;
    }

    .model-viewer-layout {
        width: 450px;
        margin-left: -225px;
    }

    .media-player-layout {
        width: 450px;
        margin-left: -225px;
    }

    .media-player-style {
        width: 450px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }
}

@media screen and (max-width: 450px) {
    .quiz-viewer-layout {
        width: 400px;
        margin-left: -200px;
    }

    .model-viewer-layout {
        width: 400px;
        margin-left: -200px;
    }

    .media-player-layout {
        width: 400px;
        margin-left: -200px;
    }

    .media-player-style {
        width: 400px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }
}

@media screen and (max-width: 415px) {
    .quiz-viewer-layout {
        width: 380px;
        margin-left: -190px;
    }

    .model-viewer-layout {
        width: 380px;
        margin-left: -190px;
    }

    .media-player-layout {
        width: 380px;
        margin-left: -190px;
    }

    .media-player-style {
        width: 380px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }

    .model-viewer-action-button {
        padding: 8px 8px 8px 8px;
    }
}

@media screen and (max-width: 400px) {
    .quiz-viewer-layout {
        width: 350px;
        margin-left: -175px;
    }

    .model-viewer-layout {
        width: 350px;
        margin-left: -175px;
    }

    .media-player-layout {
        width: 350px;
        margin-left: -175px;
    }

    .media-player-style {
        width: 350px;
    }

    .enrichment-rows-margin {
        width: 10%;
    }

    .enrichment-rows-width {
        width: 90%;
    }

    .model-viewer-action-button {
        padding: 8px 8px 8px 8px;
    }
}

@media screen and (max-width: 361px) {
    .quiz-viewer-layout {
        width: 330px;
        margin-left: -165px;
    }

    .model-viewer-layout {
        width: 330px;
        margin-left: -165px;
    }

    .media-player-layout {
        width: 330px;
        margin-left: -165px;
    }

    .media-player-style {
        width: 330px;
    }

    .enrichment-rows-margin {
        width: 5%;
    }

    .enrichment-rows-width {
        width: 95%;
    }

    .model-viewer-action-button {
        padding: 8px 4px 8px 4px;
    }
}

@media screen and (max-width: 350px) {
    .quiz-viewer-layout {
        width: 300px;
        margin-left: -150px;
    }

    .model-viewer-layout {
        width: 300px;
        margin-left: -150px;
    }

    .media-player-layout {
       width: 300px;
       margin-left: -150px;
    }

    .media-player-style {
        width: 300px;
    }

    .enrichment-rows-margin {
        width: 5%;
    }

    .enrichment-rows-width {
        width: 95%;
    }

    .model-viewer-action-button {
        padding: 8px 4px 8px 4px;
    }
}

@media screen and (max-width: 300px) {
    .quiz-viewer-layout {
        width: 285px;
        margin-left: -143px;
    }

    .model-viewer-layout {
        width: 285px;
        margin-left: -143px;
    }

    .media-player-layout {
        width: 285px;
        margin-left: -143px;
    }

    .media-player-style {
        width: 285px;
    }

    .enrichment-rows-margin {
        width: 5%;
    }

    .enrichment-rows-width {
        width: 95%;
    }

    .model-viewer-action-button {
        padding: 8px 4px 8px 4px;
    }
}